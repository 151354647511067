import React from 'react';
import { graphql } from 'gatsby';

import Category from '../../brew-components/Category';

export default function BasicCategory({ data, pageContext }) {
  const apps = data.allBrew.edges;
  return <Category apps={apps} category={pageContext.category}></Category>;
}

export const query = graphql`
  query($category: String!) {
    allBrew(
      filter: { status: { in: [$category] } }
      limit: 20
      sort: { fields: timestamp, order: DESC }
    ) {
      edges {
        node {
          id
          status
          homepage
          name
          timestamp
          token
          url
          version
          stats {
            _30
            _365
            _90
          }
          prevStats {
            _30
            _365
            _90
          }
        }
      }
    }
  }
`;
